<template>
  <div
    v-if="appConfig.VueSettingsPreRun.PersonalInfoAskForConsent"
    class="d-flex v-flex-row v-mb-sm"
  >
    <ui-checkbox
      :label="translate('cartCommon.dataConsent')"
      v-model:checked="dataConsent"
    />
  </div>
  <cart-entwash-second-step-button
    v-if="appConfig.VueSettingsPreRun.CartLayout === 'Entwash'"
    :show-messages-and-send-order="showMessagesAndSendOrder"
    :disabled="buttonDisabled"
  />
  <div
    class="v-py-xs"
    v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning"
  >
    <span
      class="v-mr-xxs v-color-mono-800 v-typography-text-6"
      v-html="translate('cartCommon.personalDataMessage')"
    />
    <arora-nuxt-link
      class-name="v-link-text-color-mono-800 v-typography-text-6 v-font-weight-500"
      open-in-new-tab
      :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
      :label="translate('cartCommon.personalDataMessageLink')"
    />
  </div>
</template>

<script setup lang="ts">
import type { CartItem, MessagesV2 } from '~types/clientStore'
import type { popupChangedProductsContent } from '~types/popupStore'

import { type GUID, useCommon, useValidationStore } from '@arora/common'

import { getActivePinia } from 'pinia'
import {
  Guid,
  MessageType,
  OrderSendErrors,
  OrderType,
  PayType,
  ReceiptSendType
} from '~api/consts'

const { translate } = useI18nSanitized()

const restaurantStore = useRestaurantStore()
const addressStore = useAddressStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const {
  districtDeliveryParametersByTerminal,
  terminal,
  terminalTemporarilyNoActive
} = useAddressSelector()
const { stringIsNullOrWhitespace } = useCommon()
const { pathname } = useUrl()
const { deleteItemsArray } = useCart()
const appConfig = useAppConfig()

const orderButtonBlocked = ref<boolean>(false)
const dataConsent = ref<boolean>(false)

const { minSumNotReached } = useAddressSelector()

const buttonDisabled = computed<boolean>(() => {
  if (!clientStore.ClientState?.data || !terminal.value) return true

  //consent check
  if (appConfig.VueSettingsPreRun.PersonalInfoAskForConsent && !dataConsent.value)
    return true

  if (minSumNotReached.value) return true

  //just in case - if client state null (somehow)
  return !clientStore.ClientState?.data
})

const payType = computed<PayType>(() => {
  return (
    clientStore.ClientState?.data?.StateOrderData?.PaymentType ?? PayType.Undefined
  )
})

const receiptSendType = computed<ReceiptSendType>(() => {
  return (
    clientStore.ClientState?.data?.StateOrderData?.ReceiptSendType ??
    ReceiptSendType.Default
  )
})

const validatorForm = 'cart'

onMounted(async () => {
  if (import.meta.client) await addressStore.initCities()
})

async function showMessagesAndSendOrder(): Promise<void> {
  await restaurantStore.Metrics?.sendMetricBeforeOrder()

  const needToShowProductsWithPriceChanges =
    await clientStore.needToShowProductsWithPriceChanges()
  const unavailableProducts = await clientStore.getUnavailableProducts()

  const popupChangedProductsContent: popupChangedProductsContent[] = []

  if (!needToShowProductsWithPriceChanges) return
  if (clientStore.productsWithPriceChanges.length > 0) {
    const productsWithPriceChangesIDs: Set<GUID> = new Set(
      clientStore.productsWithPriceChanges.map((item) => item.itemID)
    )
    const productsWithPriceChangesInCart: CartItem[] =
      clientStore.ClientState.data?.Cart?.Content?.filter((item) =>
        productsWithPriceChangesIDs.has(item.ID)
      ) ?? []

    popupChangedProductsContent.push({
      message: translate('changedProductsPopup.changesPriceProductsMessage'),
      products: productsWithPriceChangesInCart
    })
  }

  if (unavailableProducts.length > 0) {
    popupChangedProductsContent.push({
      message: translate('changedProductsPopup.unavailableProductsMessage'),
      products: unavailableProducts
    })
  }

  const productsThatExceedCount = (
    clientStore.ClientState.data?.Cart?.Content ?? []
  ).filter((item) => item.IsExceedMaxCount && !item.Removed)

  if (productsThatExceedCount.length > 0) {
    popupChangedProductsContent.push({
      message: translate('changedProductsPopup.changedMaxCountMessage'),
      products: productsThatExceedCount
    })
  }

  if (
    productsThatExceedCount.length > 0 ||
    unavailableProducts.length > 0 ||
    clientStore.productsWithPriceChanges.length > 0
  ) {
    await popupStore.showChangedProducts({
      content: popupChangedProductsContent,
      okFunction: async () => {
        await deleteItemsArray(unavailableProducts, true)
      }
    })

    return
  }

  const totalMessages = (clientStore.ClientState?.data?.MessagesV2 ?? []).filter(
    (m: MessagesV2) =>
      m.Type === MessageType.ImportantNotBlocking ||
      m.Type === MessageType.Important ||
      m.Type === MessageType.ImportantCustom ||
      m.Type === MessageType.ImportantNotBlockingCustom
  )

  if (totalMessages.length === 0) {
    await afterMessagesOrder()

    return
  }

  const messagesToShow = totalMessages
    .filter(
      (m: MessagesV2) =>
        m.Type === MessageType.Important || m.Type === MessageType.ImportantCustom
    )
    .map((m: MessagesV2) => m.Text)

  if (messagesToShow && messagesToShow.length > 0) {
    await popupStore.showError(messagesToShow.join(' \n'), 'important-message')
  } else {
    const messagesNotBlockingToShow = totalMessages
      .filter(
        (m: MessagesV2) =>
          m.Type === MessageType.ImportantNotBlocking ||
          m.Type === MessageType.ImportantNotBlockingCustom
      )
      .map((m: MessagesV2) => m.Text)

    if (messagesNotBlockingToShow && messagesNotBlockingToShow.length > 0) {
      await popupStore.showConfirm({
        message: messagesNotBlockingToShow.join(' \n'),
        noText: translate('cartCommon.notBlockingNo'),
        type: 'warning',
        yesFunction: () => {
          afterMessagesOrder()
        },
        yesText: translate('cartCommon.notBlockingYes')
      })
    }
  }
}

async function afterMessagesOrder(): Promise<void> {
  orderButtonBlocked.value = true
  try {
    if (
      appConfig.RestaurantSettingsPreRun.OneStageCart ||
      pathname === appConfig.VueSettingsPreRun.Links.CartSecondStep
    ) {
      if (await checkClientStateComplete()) {
        try {
          const answer = await clientStore.sendOrderV3()

          await (payType.value === PayType.QR
            ? popupStore.openPopup({
                popupClosable: false,
                popupName: 'qrPayPopup',
                popupProperties: new Map<string, unknown>([
                  ['orderId', answer.OrderID],
                  ['totalSum', answer.TotalAmountToPay]
                ])
              })
            : navigateTo(answer.SiteProcessingPath, { external: true }))
        } catch (error) {
          switch (error?.code) {
            case OrderSendErrors.OrderDestinationIsNotValid: {
              await popupStore.showError(
                translate('cartCommon.errorOrderDestinationIsNotValid'),
                'empty-invalid-or-forbidden'
              )
              break
            }
            case OrderSendErrors.ClientInBlacklist: {
              await popupStore.showError(
                translate('cartCommon.errorClientInBlacklist'),
                'blacklist'
              )
              break
            }
            case OrderSendErrors.CaptchaV2Failed:
            case OrderSendErrors.CaptchaV3Failed:
            case OrderSendErrors.CaptchaWasNotVerified: {
              await popupStore.showError(
                translate('accountManagement.captchaValidationError'),
                'captcha'
              )
              break
            }
            case OrderSendErrors.CartIsEmpty: {
              await popupStore.showError(
                translate('cartCommon.errorEmpty'),
                'empty-invalid-or-forbidden'
              )
              break
            }
            case OrderSendErrors.MinSumIsNotReached: {
              await popupStore.showError(
                translate('cartCommon.errorMinSumGeneral'),
                'min-sum-not-reached'
              )
              break
            }
            case OrderSendErrors.CartIsInvalid: {
              await popupStore.showError(
                translate('cartCommon.errorInvalid'),
                'empty-invalid-or-forbidden'
              )
              break
            }
            case OrderSendErrors.PromoCodeIsInvalid: {
              await popupStore.showError(
                translate('cartCommon.errorPromoCodeIsInvalid')
              )
              break
            }
            case OrderSendErrors.Unknown: {
              await popupStore.showError(
                translate('cartCommon.errorUnknown'),
                'empty-invalid-or-forbidden'
              )
              break
            }
            case OrderSendErrors.ThirdPartAPI: {
              if (error.message) {
                const accountStore = useAccountStore()

                await popupStore.showConfirm({
                  message: error.message,
                  noFunction: () => {
                    reloadNuxtApp()
                  },
                  noText: translate('confirmPopup.logoutTimeoutNo'),
                  type: 'error',
                  yesFunction: () => {
                    accountStore.logout()
                    reloadNuxtApp()
                  },
                  yesText: translate('confirmPopup.logoutTimeoutYes')
                })
                break
              }
              break
            }
            case OrderSendErrors.OrderType: {
              await popupStore.showError(translate('cartCommon.errorOrderType'))
              break
            }
            case OrderSendErrors.PaymentTypeIsUndefined: {
              await popupStore.showError(translate('cartCommon.errorPay'))
              break
            }
            default: {
              if (error.message) {
                await popupStore.showError(error.message)
              }
              console.error('Failed to send order v3', error)
            }
          }
        }
      }
    } else if (await checkClientStateFirstStage()) {
      await navigateTo(appConfig.VueSettingsPreRun.Links.CartSecondStep)
    }
  } catch (error) {
    console.error('store request error', error)
  } finally {
    orderButtonBlocked.value = false
  }
}

async function checkClientStateFirstStage(): Promise<boolean> {
  if (!clientStore.ClientState?.data) {
    console.error('Somehow there is no client state!')

    return false
  }
  if (!terminal.value) {
    console.error('Somehow there is no terminal!')

    return false
  }

  if (!validationStore.formPassedCheck(validatorForm)) {
    return false
  }

  if (clientStore.ClientState?.data.OrderType === OrderType.Default) {
    await popupStore.showError(
      translate('cartCommon.errorOrderType'),
      'empty-invalid-or-forbidden'
    )

    return false
  }

  if (
    !clientStore.ClientState?.data.Cart ||
    !clientStore.ClientState?.data.Cart.Content ||
    clientStore.ClientState?.data.Cart.Content.length === 0
  ) {
    await popupStore.showError(
      translate('cartCommon.errorEmpty'),
      'empty-invalid-or-forbidden'
    )

    return false
  }

  if (clientStore.ClientState?.data.Cart.Forbidden) {
    await popupStore.showError(
      translate('cartCommon.errorForbidden'),
      'empty-invalid-or-forbidden'
    )

    return false
  }

  if (clientStore.ClientState?.data.Invalid) {
    await popupStore.showError(
      translate('cartCommon.errorInvalid'),
      'empty-invalid-or-forbidden'
    )

    return false
  }

  switch (clientStore.ClientState?.data.OrderType) {
    case OrderType.CourierDelivery: {
      if (
        Guid.IsNullOrEmpty(
          clientStore.ClientState?.data.SelectedDeliveryAddress?.CityID
        )
      ) {
        await popupStore.showError(
          translate('cartCommon.errorDeliveryCity'),
          'wrong-address'
        )

        return false
      }
      if (
        stringIsNullOrWhitespace(
          clientStore.ClientState?.data.SelectedDeliveryAddress?.DistrictID
        )
      ) {
        await popupStore.showError(
          translate('cartCommon.errorDeliveryDistrict'),
          'wrong-address'
        )

        return false
      }
      if (
        Guid.IsNullOrEmpty(
          clientStore.ClientState?.data.SelectedDeliveryAddress?.DepartmentID
        )
      ) {
        await popupStore.showError(
          translate('cartCommon.errorDeliveryDepartment'),
          'wrong-address'
        )

        return false
      }
      if (
        Guid.IsNullOrEmpty(
          clientStore.ClientState?.data.SelectedDeliveryAddress?.TerminalID
        )
      ) {
        await popupStore.showError(
          translate('cartCommon.errorDeliveryTerminal'),
          'wrong-address'
        )

        return false
      }

      break
    }
    case OrderType.NoShipment: {
      if (
        Guid.IsNullOrEmpty(
          clientStore.ClientState?.data.SelectedSelfserviceTerminalID
        )
      ) {
        await popupStore.showError(
          translate('cartCommon.errorSelfService'),
          'wrong-address'
        )

        return false
      }

      break
    }
    case OrderType.InHall: {
      if (
        Guid.IsNullOrEmpty(clientStore.ClientState?.data.SelectedInHall?.TerminalID)
      ) {
        await popupStore.showError(
          translate('cartCommon.errorInHall'),
          'wrong-address'
        )

        return false
      }
      if ((clientStore.ClientState?.data.SelectedInHall?.TableNumber ?? 0) === 0) {
        await popupStore.showError(
          translate('cartCommon.errorInHall'),
          'wrong-address'
        )

        return false
      }

      break
    }
  }

  const terminalCheck = await addressStore.checkTerminal(terminal.value.ID)

  if (!terminalCheck.enableTerminal) {
    await popupStore.showError(
      terminalCheck.unavailableMessage.length > 0
        ? terminalCheck.unavailableMessage
        : translate('cartCommon.errorDeliveryTerminal')
    )

    return false
  }

  const districtDeliveryParameters = districtDeliveryParametersByTerminal()

  if (districtDeliveryParameters.IsPriceRulesOn) {
    const amount =
      clientStore.ClientState?.data?.Cart?.ToPayWithMoneyActive?.Amount ?? 0
    if (districtDeliveryParameters.PriceRules.length === 0) {
      //misconfiguration, but still there should be a notification
      await popupStore.showError(translate('cartCommon.errorInternalSettings'))

      return false
    } else if (
      amount <
      (clientStore.ClientState.data?.DeliveryPaymentInfo?.ModifiedMinSum ?? 0)
    ) {
      await popupStore.showError(
        translate('cartCommon.errorMinSum', {
          minsum:
            clientStore.ClientState.data?.DeliveryPaymentInfo?.ModifiedMinSum ?? 0
        }),
        'min-sum-not-reached'
      )

      return false
    }
  }

  if (terminalTemporarilyNoActive.value.IsTerminalNoActive) {
    if (terminalTemporarilyNoActive.value.IsTemporarilyNonActive) {
      await popupStore.showError(
        translate('errorNotifier.infoTerminalTemporarilyNonActive'),
        'unavailable-terminal'
      )

      return false
    } else if (terminalTemporarilyNoActive.value.TemporarilyUnavailableForDelivery) {
      await popupStore.showError(
        translate('errorNotifier.infoDeliveryTemporarilyUnavailable'),
        'unavailable-terminal'
      )

      return false
    } else if (
      terminalTemporarilyNoActive.value.TemporarilyUnavailableForSelfService
    ) {
      await popupStore.showError(
        translate('errorNotifier.infoSelfServiceTemporarilyUnavailable'),
        'unavailable-terminal'
      )

      return false
    }
  }

  return true
}

async function checkClientStateComplete(): Promise<boolean> {
  if (!(await checkClientStateFirstStage())) {
    //popup with error was already called at this point
    //here we are only stop any further checks
    return false
  }
  if (!clientStore.ClientState?.data?.StateOrderData) {
    console.error('Somehow there is no StateOrderData!')

    return false
  }

  if (stringIsNullOrWhitespace(clientStore.ClientState?.data.StateOrderData.Name)) {
    await popupStore.showError(
      translate('cartCommon.errorPersonalInfo'),
      'personal-info-not-filled'
    )

    return false
  }

  if (stringIsNullOrWhitespace(clientStore.ClientState?.data.StateOrderData.Phone)) {
    await popupStore.showError(
      translate('cartCommon.errorPersonalInfo'),
      'personal-info-not-filled'
    )

    return false
  }

  if (clientStore.courierDelivery) {
    if (
      stringIsNullOrWhitespace(
        clientStore.ClientState?.data?.SelectedDeliveryAddress?.Street
      ) &&
      stringIsNullOrWhitespace(
        clientStore.ClientState?.data?.SelectedDeliveryAddress?.GeoCity
      )
    ) {
      // Стоит немного скорректировать условие валидации
      await popupStore.showError(
        translate('cartCommon.errorDeliveryStreet'),
        'wrong-address'
      )

      return false
    }

    if (
      stringIsNullOrWhitespace(
        clientStore.ClientState?.data.SelectedDeliveryAddress?.House
      )
    ) {
      await popupStore.showError(
        translate('cartCommon.errorDeliveryHouse'),
        'wrong-address'
      )

      return false
    }
  }

  if (payType.value === PayType.Undefined) {
    await popupStore.showError(translate('cartCommon.errorPay'), 'no-pay-selected')

    return false
  }

  if (
    appConfig.VueSettingsPreRun.ReceiptSendTypes.length > 0 &&
    receiptSendType.value === ReceiptSendType.Default
  ) {
    await popupStore.showError(
      translate('cartCommon.errorRecipient'),
      'no-receipt-selected'
    )

    return false
  }

  return true
}
</script>
